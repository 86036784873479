.slider-thumbs{
    .swiper-slide{
        @apply border border-gray-100 shadow transition-all duration-300;
        img{
            @apply opacity-50;
        }
        &.swiper-slide-thumb-active{
            @apply border-gray-300 shadow-lg;
            img{
                @apply opacity-100;
            }
        }
    }
}

.swiper-button-next, .swiper-button-prev{
    @apply absolute w-12 h-12;
    top: 50%;
    transform:translateY(-50%);
    &.swiper-button-disabled{
        display: none;
    }
}
.swiper-button-prev{
    left:  0;
}
.swiper-button-next{
    right:  0;
}
