@import 'tailwindcss/base';

@import 'common/global.scss';

@import 'partials/header.scss';
@import 'partials/footer.scss';


@import 'components/index.scss';
@import 'config/external.scss';


@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

