.common-content{
    @apply leading-normal text-sm;
    @screen md{
        @apply text-base leading-relaxed;
    }

    p {
        margin-bottom: 10px;
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    a{
        @apply font-semibold border-b border-primary;
        &:hover{
            @apply border-gray-300;
        }
    }

    ul, ol{
        @apply list-disc mb-3;
        li{
            margin-left: 15px;
            &::marker{
                @apply text-black;
            }
        }
    }
    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        max-width: 100%;
        height: auto;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.compat-object-fit{
    background-size: cover;
    background-position: center center;
    picture, img{
        visibility: hidden;
        background: rgba(0,0,0,0.5);
        @apply opacity-0;
    }

}


.sticky-product{
    @screen lg{
        top: 120px;
    }
}



.zoom__item {
    width: 100%;
    position: relative;
    cursor: pointer;
}

.zoom__img {
    position: relative;
    img {
        width: 100%;
        margin: 0;
    }
}
.zoom__overlay {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow:hidden;
    opacity: 0;
    transition: opacity .4s;

    .zoom__overlay__img {
        position: absolute;
        left: 0px;
        top: 0px;
        min-width: 100%;
        img {
            //min-width: 100%;
            max-width: none;
        }
    }

    &.is-visible {
        opacity: 1;
        transition: opacity .4s;
    }
}


.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
