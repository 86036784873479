/**
 * Components
 */


@import 'hero.scss';
@import 'intro-home.scss';
@import 'tabs-section.scss';
@import 'slider-horizontal.scss';
@import 'home-blocks.scss';
@import 'product-gallery.scss';
@import 'lists.scss';
@import 'reviews.scss';
@import 'page-content.scss';
@import 'tabs.scss';
@import 'tables.scss';
@import 'forms.scss';
