.tab-product {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;


    .tabs {
        li {
            @screen lg{
                @apply flex-1;
            }
            a{
                @apply block px-4 py-2 text-sm bg-white w-full border-b-2 border-black;
                @screen md{
                    @apply text-base
                }
                @screen lg{
                    @apply px-6 py-3 font-semibold w-auto border-b-0 border-r-2;
                }
                &:hover{
                    @apply bg-gray-200;
                }
            }
            &:last-of-type{
                a{
                    @apply border-0;
                }
            }
            &.current{
                a{
                    @apply bg-primary;
                    &:hover{
                        @apply bg-primary;
                    }
                }
            }
        }

    }

    .tabs_item {
        @apply hidden pt-6;
        &:first-child {
            @apply block;
        }
    }



}
