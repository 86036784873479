.tabs-section{
    .tabs{
        &__trigger{
            @apply border-b border-transparent;
            height: 220px;
            border-bottom-width: 5px;
            @screen md{
                height: 350px;
            }
            &__img{
                &:after{
                    content: '';
                    @apply absolute inset-0;
                    background: rgba(0,0,0,0.3);
                    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.3));
                }
            }
            &.current{
                @apply border-secondary;
            }
        }

    }
    .tab-content{
        .tabs-item{
            @apply hidden;
            &:first-of-type{
                @apply block;
            }
        }
    }
}
