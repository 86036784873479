.section-hero{
    height: 70vh;
    min-height: 500px;
    &__slide{
        @apply relative overflow-hidden;
        &--gradient{
            &:before{
                content: '';
                @apply absolute inset-0 z-10;
                background: rgba(0,0,0,0.5);
                background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5));
            }
        }
    }
}

.section-hero-page{

    &__slide{
        @apply relative overflow-hidden;
        &--gradient{
            &:before{
                content: '';
                @apply absolute inset-0 z-10;
                background: rgba(0,0,0,0.5);
                background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5));
            }
        }
    }
}
