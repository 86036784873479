/**
 * Global
 */

body {
  @apply font-sans text-black;
}

.container{
  @screen xl{
    max-width: 90rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply mb-2;
}

p {
  @apply mb-3;
  &:last-of-type{
    @apply mb-0;
  }
}

button {
  @apply transition-all duration-300;
  outline: 0 !important;
}
a {
  outline: 0 !important;
  @apply transition-all duration-300;
}


@font-face{
  font-family:'HNBC';
  src:url('/dist/fonts/helvetica-neue-bold.woff2') format('woff2'),
  url('/dist/fonts/helvetica-neue-bold.woff') format('woff');
  font-weight:700;
  font-style:normal;
  font-display:swap
}
.block-title{
    @apply mb-3 lg:mb-6;
    span{
        @apply font-title block text-primary;

        &:first-of-type{
          @apply text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl;
        }
        &:last-of-type{
            @apply text-2xl text-black;
            @screen lg{
                @apply text-3xl;
            }
            @screen xl{
                @apply text-4xl;
            }
            @screen 2xl{
                @apply text-5xl;
            }
        }



    }
}
.product-title{
    span{
        @apply font-title block text-primary;
        &:first-of-type{
            @apply text-2xl text-black;
        }

        &:last-of-type{
            @apply text-2xl text-black;
            @screen lg{
                @apply text-3xl;
            }
            @screen xl{
                @apply text-4xl;
            }
            @screen 2xl{
                @apply text-5xl;
            }
        }
        &:nth-child(2){
            @apply text-primary;
        }


    }
}
.hero-title{
  span{
    @apply font-title block text-primary;
    &:first-of-type{
        line-height: 0.8;
        font-size: 2.8vw;
    }
    &:last-of-type{
        @apply text-white;
        line-height: 0.8;
        font-size: 2vw;
    }
  }
}

.title-page{
  span{
    @apply font-title block text-primary;
    &:first-of-type{
        @apply text-white;
        line-height: 0.8;
        font-size: 2.8vw;
    }
    &:last-of-type{
        @apply text-black;
        line-height: 0.8;
        font-size: 2vw;
    }
  }
}

.text-title-home{
  span{
    @apply font-title block text-white;
    line-height: 0.8;
    &:last-of-type{
      span{
        @apply text-black;
      }
    }
    &:last-of-type{
      span{
        @apply text-black;
      }
    }
  }
}
