.intro-home{
    @apply relative;
/*
    &__ring{
        @apply relative;
        margin-left: 40px;
        &__badge{
            @apply absolute top-0 left-0 border-b border-white mb-0;
            height: 40px;
            transform:rotate(90deg);
            transform-origin: 0 0;
        }
    }*/
}
