.slider-horizontal{
    @apply relative;

    &__thumb{
        max-height: 270px;
        @apply block overflow-hidden;
    }
    &__nav{
        @apply absolute rounded-full bg-primary flex items-center justify-center;
        top: 50%;
        z-index: 40;
        width:24px;
        height:24px;

        @screen lg{
            width: 35px;
            height: 35px;
        }
        @screen xl{
            width: 45px;
            height: 45px;
        }
        svg{
            position: relative;
            @apply text-white;
        }
        &--prev{
            left: 0;
            transform:translateX(-50%) translateY(-50%);

            &.swiper-button-disabled{
                display: none !important;
            }

        }
        &--next{
            right: 0;
            transform:translateX(50%) translateY(-50%);
            svg{
                position: relative;
            }
            &.swiper-button-disabled{
                display: none !important;
            }
        }
    }
}
