
.tablesaw {
  width: 100%;
  max-width: 100%;
  empty-cells: show;
  border-collapse: collapse;
  border: 0;
  padding: 0;
}

.tablesaw * {
  box-sizing: border-box;
}

.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
  display: none;
}


@media only all {

  .tablesaw-stack {
    clear: both;
  }

  .tablesaw-stack td,
  .tablesaw-stack th {
    display: block;
  }

  .tablesaw-stack tr {
    clear: both;
    display: table-row;
  }


  .tablesaw-stack td .tablesaw-cell-label,
  .tablesaw-stack th .tablesaw-cell-label {
    display: inline-block;
    padding: 0 .6em 0 0;
    width: 30%;
  }

  /* For grouped headers, have a different style to visually separate the levels by classing the first label in each col group */

  .tablesaw-stack th .tablesaw-cell-label-top,
  .tablesaw-stack td .tablesaw-cell-label-top {
    display: block;
    padding: .4em 0;
    margin: .4em 0;
  }

  .tablesaw-cell-label {
    display: block;
  }

  /* Avoid double strokes when stacked */

  .tablesaw-stack tbody th.group {
    margin-top: -1px;
  }

  /* Avoid double strokes when stacked */

  .tablesaw-stack th.group b.tablesaw-cell-label {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  /* Table rows have a gray bottom stroke by default */

  .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    @apply border-b border-dashed border-gray-400;
  }

  .tablesaw-stack thead td,
  .tablesaw-stack thead th {
    display: none;
  }

  .tablesaw-stack tbody td,
  .tablesaw-stack tbody th {
    display: block;
    float: left;
    clear: left;
    width: 100%;
  }

  .tablesaw-cell-label {
    vertical-align: top;
  }

  .tablesaw-cell-content {
    display: inline-block;
    max-width: 67%;
  }

  .tablesaw-stack .tablesaw-stack-block .tablesaw-cell-label,
  .tablesaw-stack .tablesaw-stack-block .tablesaw-cell-content {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .tablesaw-stack td:empty,
  .tablesaw-stack th:empty {
    display: none;
  }
}


@media (min-width: 1024px) {
  .tablesaw-stack tr {
    display: table-row;
  }

  /* Show the table header rows */

  .tablesaw-stack td,
  .tablesaw-stack th,
  .tablesaw-stack thead td,
  .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }

  /* Hide the labels in each cell */

  .tablesaw-stack td .tablesaw-cell-label,
  .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}


.responsive-table{
  td, th{
    @screen lg{
      @apply text-center;
    }
  }
  thead{
   th{
    vertical-align: bottom;
    @screen lg{
      padding:  0 !important;
    }
   }
  }
  tbody{
    @apply text-sm;
    tr{
      @screen lg{
        td{
          @apply py-1 px-2;
        }
        &:nth-child(odd){
          td{
            @apply bg-gray-200;
          }
        }
      }

    }
  }
  .title{
    @apply font-semibold;
  }
}

.tablesaw-cell-content{
  @apply px-2 py-1;
}
