.home-blocks {
    &__odd{
        @apply relative;
        &:before{
            content: '';
            @apply absolute inset-0 bg-gray-200 z-0;
            left: -999vw;
            right: 25vw;
        }
    }
    &__even{
        @apply relative;
        &:before{
            content: '';
            @apply absolute inset-0 bg-gray-300 z-0;
            right: -999vw;
            left: 50px;
        }
    }
}
