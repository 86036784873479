.form-group{
    @apply mt-2 block relative;
    input[type=text], input[type=email], textarea{
        @apply shadow appearance-none border rounded border border-gray-400 w-full py-2 px-3 text-gray-700 leading-tight;
        resize: none;
        width: 100%;
        outline: 0 !important;
        &:focus{
            @apply shadow-lg border-black ring-primary;
        }
    }
    label{
        @apply block text-gray-700 text-sm font-bold mb-2;
    }

    select{
        @apply block appearance-none w-full bg-white border border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight;
        outline: 0 !important;
        cursor: pointer;
        &:focus{
            @apply shadow-lg border-black ring-primary;
        }
    }
}

.captcha{
    .RadCaptcha {
        div{
            @apply flex flex-wrap;
            @screen lg{
                @apply flex-nowrap;
            }
        }
        p{
            @screen lg{
                @apply flex-1 mb-0;
                padding-left: 10px;
            }
            input{
                height: 50px;
                outline: 0 !important;
                @apply shadow appearance-none border rounded border border-gray-400 w-full py-2 px-3 text-gray-700 leading-tight;
                &:focus{
                    @apply shadow-lg border-black ring-primary;
                }
                @screen lg{
                    @apply text-lg;
                }
                .controls-contact &{
                    @apply border border-primary;
                }
            }
            label{
                @apply text-gray-700 block mt-1 text-sm text-center;
                .controls-contact &{
                    @apply text-primary;
                }
            }
        }
    }
}
