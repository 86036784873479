.header-app {
    min-height: 80px;
    @screen lg{
        min-height: 90px;
    }

    .header-app__logo{
        height: 80px;
        @screen lg{
            height: 90px;
        }
        svg, img{
            width: 90px;
            @screen md{
                width: 110px;
            }
        }
    }
    .nav-primary{
        @apply absolute bg-white transition-all duration-500;
        top: 80px;
        left: 0;
        right: 0;
        max-height: calc(100vh - 80px);
        @media (max-width: 1023px){
            &.opened{
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                @apply block;

            }

        }

        @screen lg{
            @apply relative bg-transparent;
            min-height: 0;
            top: 0;
            height: 90px;
        }
        .nav-primary__inner{
            @apply pt-3 pb-12 transition duration-300;
            @screen lg{
                @apply flex items-center py-0;

            }
        }

        li{
            margin-bottom: 0;
        }
        .nav-primary__li{
            .submenu{
                li{
                    @apply px-5 pb-1;
                    @screen md{
                        @apply px-8;
                    }
                    .submenu_sub{
                        @apply py-2;
                        li{
                            @apply pb-0;
                        }
                    }
                }
            }
        }
        .nav-primary__li{
            @apply relative;
            border-bottom: 1px solid rgba(0,0,0,0.07);
            &:last-of-type{
                border-bottom: 0;
            }
            .submenu{
                @apply pb-8;
            }
            @screen lg{
                border-bottom: 0;
                .submenu{
                    @apply absolute shadow-lg transition-all duration-300 pointer-events-none pt-4 pb-4;
                    visibility: hidden;
                    min-width: 210px;
                    @apply opacity-0 bg-white;
                    top: 80%;
                    left: 10px;
                    transform:translate(0, 20px);
                    @screen 2xl{
                        min-width: 230px;
                    }
                    &.active-lg{
                        @screen lg{
                            @apply pointer-events-auto opacity-100;
                            visibility: visible;
                            transform:translate(0, 0);
                            &__arrow{
                                visibility: visible;
                                @apply transition-all duration-300 opacity-100;
                                transform: translateX(-50%) translateY(0) rotate(45deg);
                            }
                        }

                    }
                    &__arrow{
                        @apply block absolute transition-all duration-300 top-0 bg-white pointer-events-none;
                        margin-top: -5px;
                        left: 50%;
                        top:  80%;
                        transform:translateX(-50%) translateY(20px) rotate(45deg);
                        width: 15px;
                        height: 15px;
                        @apply opacity-0;
                        visibility: hidden;
                    }
                    li{
                        @apply mb-1 relative px-6;
                        a{
                            @apply block;

                        }
                        &:hover{
                            @apply text-secondary;
                        }

                        &.active{
                            a{
                                @apply font-semibold;
                            }
                        }
                        .submenu_sub{
                            @apply absolute bg-primary py-0 px-0 transition-all duration-300 shadow-none pointer-events-none;
                            min-width: 210px;
                            visibility: hidden;
                            @apply opacity-0;
                            top: -10%;
                            left: 100%;
                            transform:translate(0, 20px);
                            @screen lg{
                                @apply shadow-lg;
                            }
                            &.active-lg{
                                @screen lg{
                                    @apply pointer-events-auto opacity-100;
                                    visibility: visible;
                                    transform: translate(0, 0);
                                }
                            }
                            li{
                                @apply px-0 mb-0;
                                a{
                                    @apply text-black block font-semibold px-4 py-[8px];
                                    &:hover{
                                        @apply bg-white bg-opacity-50;
                                    }
                                }
                            }
                            @screen 2xl{
                                min-width: 230px;
                            }

                        }
/*                        &:hover{
                            .submenu_sub{
                                @apply pointer-events-auto opacity-100;
                                visibility: visible;
                                transform: translate(0, 0);
                            }

                        }*/

                    }
                }
/*                &:hover{
                    .submenu{

                    }

                }*/


            }

            &:last-of-type{
                @screen lg{
                    .submenu{
                        left: auto;
                        right:  0;
                    }


                }
            }
        }
        .nav-primary__link{
            @apply font-semibold block transition duration-0;
            position: relative;
            overflow: hidden;
            z-index: 1;
            &:hover{
                @apply text-black;
            }
            &.active{
                @apply text-black;
            }
        }

    }


    .hamburger{
        svg{
            @apply text-black fill-current stroke-current;
            width: 25px;
            height: 25px;
            margin-top: 3px;
        }
    }



}
.element-plus{
    @apply hidden;
    @screen lg{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 7px;
        margin-right: 5px;
        font-size: 1rem;
        display: block;
    }
}
